import { render, staticRenderFns } from "./stage-modal.vue?vue&type=template&id=4f537365&scoped=true&"
import script from "./stage-modal.vue?vue&type=script&lang=js&"
export * from "./stage-modal.vue?vue&type=script&lang=js&"
import style0 from "./stage-modal.vue?vue&type=style&index=0&id=4f537365&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f537365",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/jenkins/jobs/management-system-frontend/workspace/ant-design-vue-jeecg/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4f537365')) {
      api.createRecord('4f537365', component.options)
    } else {
      api.reload('4f537365', component.options)
    }
    module.hot.accept("./stage-modal.vue?vue&type=template&id=4f537365&scoped=true&", function () {
      api.rerender('4f537365', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/system/details/platform/stage-modal.vue"
export default component.exports